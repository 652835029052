@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @supports (backdrop-filter: blur(12px)) {
    .blur-blue {
      backdrop-filter: blur(12px);
    }
  }

  @supports not (backdrop-filter: blur(12px)) {
    .dark .blur-blue {
      background-color: #13171f;
    }
    .blur-blue {
      background-color: rgba(255, 255, 255);
    }
  }
}
